/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --menu-background-color: #fff;
    --menu-desktop-background-color: rgba(122, 122, 122, .44);
    --menu-desktop-height: 60px;
    --menu-item-figure-background: var(--secondary-base-color);
    --menu-item-hover-color: var(--primary-base-color);
    --menu-second-level-color: #adadad;
}

@mixin subcategory-visible {
    pointer-events: all;
    z-index: 10;
    opacity: 1;
    transform: translateX(0);
}

.Menu {
    @include mobile {
        &-MenuWrapper {
            background: rgba(0,0,0,.65);
            pointer-events: all;
            touch-action: pan-y;

            li {
                padding-left: 0;

                &::before {
                    content: none;
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        &-Overlay {
            background-color: var(--menu-desktop-background-color);
            height: 100vh;
            width: 100%;
            position: absolute;
            z-index: -1;
            top: -3px;
            left: 0;
            opacity: 0;
            transition: opacity 200ms ease-in;
            will-change: transition;

            &_isVisible {
                opacity: 1;
            }
        }

        &-MainCategories {
            display: flex;
            flex-direction: column;
        }

        &-ItemList {
            display: grid;

            &_type {
                &_main {
                    order: 2;
                }

                &_subcategory {
                    background: var(--menu-background-color);
                    grid-auto-rows: max-content;
                    padding: 0 1rem;
                }
            }
        }

        &-ItemCaption {
            &_type {
                &_main {
                    font-size: 1.5rem;
                    line-height: 1.2;
                    text-align: left;
                }
            }

            &_isLogo {
                font-size: 11px;
            }

            &_isBanner {
                --button-background: var(--homepage-button-background);
                --button-border: transparent;
                --button-color: var(--homepage-button-color);
                --button-hover-background: var(--homepage-button-color);
                --button-hover-border: transparent;
                --button-hover-color: var(--homepage-button-background);
                --button-padding: 9px 49px;

                bottom: 20px;
                left: 50%;
                position: absolute;
                transform: translateX(-50%);
            }
        }

        &-ItemCaption_Bold {
            font-weight: 700;
        }

        &-ItemCaption_Pink {
            color: $foux;
        }

        &-ItemCaption_Gold {
            color: $gold;
        }

        &-ItemCaption_Green {
            color: $green;
        }

        &-ItemCaption_Bkg {
            background: $greyF0F0F0;
            margin-left: 0;
            margin-right: 0;
            padding-left: 16px !important;
            padding-right: 16px !important;
        }

        &-ItemFigure {
            align-items: center;
            display: grid;

            &_type {
                &_main {
                    padding-left: 30px;
                }
            }

            &_isBanner {

            }

            &_isLogo {
                display: flex;

                .Image-Image {
                    object-fit: cover;
                }
            }
        }

        &-Item {
            padding-left: 0;

            &:first-child {
                margin-top: 0;
            }

            button {
                width: 100%;
            }

            .Image {
                background: none;
                display: none;
            }

            .Menu-SubMenu {
                svg {
                    margin-top: -22px;
                }
            }
        }

        &-SubCategoriesWrapper {
            position: absolute;
            top: calc(var(--header-nav-height) + 3px);
            pointer-events: none;
            width: 100%;

            @include tablet {
                top: var(--header-nav-height);
                left: 0;
            }

            &::before {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                top: calc(var(--header-nav-height) * -1);
            }

            &_isVisible {
                pointer-events: all;
            }
        }

        &-SubCategoriesWrapperInner {
            background-color: var(--overlay-background);
            transition: clip-path 200ms ease-in;
            will-change: clip-path;
            clip-path: inset(0 0 100% 0);
            margin: auto;

            &_isVisible {
                clip-path: inset(0);
            }

            @media (min-width: 1024px) and (max-width: 1130px) {
                max-height: calc(80vh - var(--header-nav-height));
                overflow-y: auto;
            }
        }

        &-SubCategories,
        &-Promotion {
            max-width: var(--content-wrapper-width);
            margin: auto;
        }

        &-Promotion {
            order: 3;
        }

        &-SubCategories {
            padding: 40px 55px 60px 1rem;

            &-promo {
                margin-bottom: 30px;
            }

            &-promos {
                padding: 38px 16px 20px;
            }

            &-promo_content {

                h3 {
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 26px;
                    letter-spacing: -0.2px;
                    margin: 0;
                }

                h4 {
                    font-size: 14px;
                    line-height: 22px;
                    letter-spacing: -0.0008em;
                }

                a {
                    font-size: 13px;
                    line-height: 20px;
                    text-decoration: underline;
                }
            }

            &-promo_image {
                img {
                    position: static !important;
                    margin-bottom: 12px;
                    margin-top: 0 !important;

                    @include mobile {
                        width: 100% !important;
                        height: auto !important;
                    }
                }
            }
        }

        &-SubMenu {
            background-color: var(--overlay-background);
            opacity: 0;
            pointer-events: none;
            touch-action: pan-y;
            transform: translateX(100%);
            transition: transform 200ms ease-in-out;
            height: 100%;
            left: 0;
            padding-top: var(--header-total-height);
            position: fixed;
            top: 0;
            width: 100%;

            &_isVisible {
                @include subcategory-visible;

                padding: 0;
            }
        }

        &-SubItemWrapper {
            flex-basis: 200px;

            &_isBanner {
                flex-grow: 1;
                flex-basis: 400px;
            }

            > .Menu-Link {
                display: block;
            }
        }

        &-SubLevelDesktop {
            .Menu {
                &-ItemList {
                    display: block;
                    padding: 0;

                    &_isBanner {
                        display: grid;
                        grid-column-gap: 10px;
                        grid-template-columns: repeat(auto-fit, 200px);
                    }
                }

                &-Link {
                    margin-top: 20px;
                }
            }
        }

        &-Image {
            &_isBanner {
                img {
                    object-fit: cover;
                }
            }

            &_isLogo {
                height: 44px;
                margin-right: 20px;
                width: 44px;
            }

            &_type {
                &_main {
                    grid-column: 2;
                    height: 100%;
                    justify-self: end;
                    padding-bottom: 0;
                }

                &_subcategory {
                    border-radius: 50%;
                    height: 3.2rem;
                    padding-bottom: 0;
                    width: 3.2rem;
                }
            }
        }

        &-Link {
            color: inherit;
        }

        &-Link + &-Link {
            margin-left: 0;
        }

        .Image {
            background: none;
        }
    }
}

.Menu {
    @include mobile {
        &_isVisible {
            background: rgba(0,0,0,.65);
        }

        &-Wrapper {
            display: grid;
            grid-template-columns: 100% 100%;

            transition: transform 200ms;
            will-change: transform;
            transform: translate3d(0, 0, 0);

            &_isSubCategoryVisible {
                transform: translate3d(-100%, 0, 0);
            }
        }

        &-Menu {
            padding: 12px 0 0;
            position: static;

            li {
                padding-left: 0;

                &::before {
                    content: none;
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        &-Banner {
            height: 120px;
            display: flex;
            justify-content: center;
            margin-bottom: .8rem;
            width: 100%;

            picture {
                padding-bottom: 0;
                height: 100%;
            }

            img {
                object-fit: cover;
            }

            figcaption {
                position: absolute;
                top: 0;
                padding: 2rem 1rem;
                text-align: center;
                font-size: 1.8rem;
                color: white;
                line-height: 1.3;
            }

            strong {
                display: block;
            }
        }

        &-SubMenu {
            touch-action: pan-y;
            pointer-events: none;
            opacity: 0;
            transition: transform 200ms ease-in-out;
            transform: translateX(100%);
            background-color: #fff;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            padding-top: var(--header-total-height);

            .MenuOverlay-SubMenu {
                left: 0;
                width: 100%;
            }

            &_isVisible {
                @include subcategory-visible;

                padding: 0;
            }
        }

        &-ItemList {
            display: grid;
            position: static;

            &_type {
                &_subcategory {
                    grid-auto-rows: max-content;
                    grid-row-gap: 0;
                    padding:  0;
                    background: #fff;

                    & > div {
                        position: static;
                    }
                }
            }
        }

        &-ItemListHeading {
            grid-column: span 2;
            text-align: center;
            margin-top: 4rem;
            margin-bottom: 1rem;
            font-size: 2rem;
            font-weight: bold;
        }

        &-Item {
            position: static;
            margin: 0;

            & > div {
                position: static;
            }
            button {
                width: 100%;
            }
            svg, img {
                width: 7px;
                height: 12px;
                position: absolute;
                right: 20px;
                fill: black;
                margin-top: -33px;
            }
            .MenuOverlay-SubMenu {
                svg {
                    margin-top: -20px;
                }
            }
        }

        &-ItemCaption {
            font-size: 14px;
            line-height: 22px;
            letter-spacing: -0.0008em;
            text-align: left;
            padding: 15px 0;
            border-bottom: 1px solid $icegrey;

            &_type {
                &_trending {
                    padding-top: .8rem;
                    justify-self: center;
                }

                &_banner {
                    position: absolute;
                    justify-self: center;
                    color: var(--menu-overlay-item-banner-color);
                }
            }
        }

        &-ItemFigure {
            display: grid;
            align-items: center;
            width: 100%;

            &_type {
                &_main {
                    height: 47px;
                    padding: 0 0 0 20px;
                }
            }
        }

        &-Image_type {
            &_main {
                grid-column: 2;
                height: 100%;
                padding-bottom: 0;
                justify-self: end;
            }

            &_banner {
                padding-bottom: 25%;
            }

            &_subcategory {
                width: 3.2rem;
                height: 3.2rem;
                padding-bottom: 0;
                border-radius: 50%;
            }

            &_trending {
                padding-bottom: 100%;

                img {
                    object-fit: cover;
                }
            }
        }

        &-HorizontalRule {
            background-color: var(--menu-overlay-horizontal-rule-background);
            height: 1px;
            width: 100%;
            padding: 0 .8rem 0 .8rem;
            margin-top: 1.5rem;
        }

        &-PageLink {
            text-align: center;
            margin: 1.5rem 0;
        }

        &-Social {
            display: flex;
            justify-content: center;
            margin-top: 2.5rem;

            h3 {
                position: absolute;
                overflow: hidden;
                clip: rect(0 0 0 0);
                height: 1px;
                width: 1px;
                margin: -1px;
                padding: 0;
                border: 0;
            }

            ul {
                display: grid;
                grid-auto-flow: column;
                width: 14rem;
                justify-items: center;
            }

            li {
                width: 1.9rem;
            }
        }

        &-AdditionalInformation {
            border-top: 1px solid var(--menu-overlay-additional-information-divider-color);
            margin-top: 2rem;
        }

        &-Link {
            color: inherit;

            &:last-child {
                &::after {
                    content: none;
                }
            }

            &:hover {
                text-decoration: none;
            }
        }

        &-Link + &-Link {
            margin-left: 0;
        }

        .Image {
            background: none;
            display: none;
        }
    }
}

.burger {
    @include mobile {
        display: none;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(black, .65);
        z-index: 100000;
        transition: opacity $ease-main;
        opacity: 0;

        &.is-active {
            opacity: 1;
        }

        $b: &;

        &__background {
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            cursor: pointer;
        }

        &__close {
            background: $white;
            width: 36px;
            height: 36px;
            padding: 5px;
            fill: white;
            transition: opacity $ease-main;
            cursor: pointer;
            position: fixed;
            right: 0;
            top: 0;
            z-index: 100;

            &:hover {
                opacity: .5;
            }

            svg {
                vertical-align: top;
            }
        }

        &__wrapper {
            box-sizing: border-box;
            width: 100%;
            height: 100%;
            overflow-x: hidden;
            transform: translate3d(100%, 0, 0);
            transition: transform $ease-main;
            background: none;

            .MenuPage & {
                transform: translate3d(0, 0, 0);
            }
        }

        &__container {
            background: white;
            pointer-events: all;

            .Header {
                &-WebsiteSwitchColumn {
                    margin-bottom: 22px;
                }
            }
        }

        // Dropdowns

        &__dropdowns {
            display: flex;
            border-bottom: 1px solid black;
        }

        &-current-category-title {
            background: $color-grey-13;
            padding: 0 16px;

            &.active {
                padding-left: 45px;
            }

            h1 {
                height: 42px;
                font-size: 14px;
                line-height: 22px;
                letter-spacing: -0.0008em;
                position: relative;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                line-height: 42px;

                &:empty {
                    display: none;
                }
            }
        }

        &-back-button {
            .Header-Button {
                display: none;

                &_isVisible {
                    display: block;
                    position: absolute;
                    z-index: 1;
                    top: 10px;
                    left: 16px;
                    width: 24px;
                    height: 24px;

                    img{
                        width: 6px;
                        height: 12px;
                    }
                }
                &::before,
                &::after {
                    display: none;
                }
            }
        }

        &__dropdown-wrapper {
            position: relative;
            width: 50%;

            &:not(:first-child) {
                border-left: 1px solid black;
            }

            .CurrencySwitcher,
            .StoreSwitcher {
                select {
                    font-size: 11px;
                    line-height: 36px;
                    padding-left: 15px;

                    &:hover {
                        opacity: 0.5;
                    }
                }
            }
        }

        &__dropdown-button {
            display: flex;
            align-items: center;
            cursor: pointer;
            transition: opacity $ease-main;
            height: 36px;
            justify-content: center;

            &:hover {
                opacity: .5;
            }

            span {
                font-size: 11px;
                line-height: 13px;
                letter-spacing: .06em;
            }

            svg {
                width: 9px;
                height: 9px;
                margin-left: 4px;
                transition: transform $ease-main;
            }

            &.is-active svg {
                transform: rotate(180deg);
            }
        }

        &__dropdown-content {
            margin-top: 1px;
        }

        // Items

        &__item {
            display: flex;
            align-items: center;
            height: 52px;
            padding: 0 16px;

            .Image,
            svg {
                flex-shrink: 0;
                width: 14px !important;
                height: auto !important;
                margin-right: 14px;
                padding-bottom: 0;
                display: inline-block;
            }

            .Image {
                img {
                    position: static;
                    width: auto !important;
                    height: auto !important;
                }
            }

            span {
                font-size: 14px;
                line-height: 14px;
                letter-spacing: -0.0008em;
                display: flex;
                width: 100%;
                height: 100%;
                align-items: center;
                border-bottom: 1px solid $icegrey;
            }
        }

        &__item_title {
            background: $greyF0F0F0;
            border-bottom: 1px solid $grey;
            border-top: 1px solid $grey;
        }

        &__item_account {
            display: flex;
            align-items: center;
            height: 52px;
            padding: 0 16px;
            position: relative;
            background: $black;
            color: $white;

            .Image,
            svg {
                flex-shrink: 0;
                width: 24px !important;
                height: 25px !important;
                margin-right: 14px;
                padding-bottom: 0;
                display: inline-block;
                position: absolute;
                left: 16px;
                top: 14px;
            }

            .Image {
                img {
                    position: static;
                    width: auto !important;
                    height: auto !important;
                }
            }

            span {
                font-size: 14px;
                line-height: 14px;
                letter-spacing: -0.0008em;
                display: flex;
                width: 100%;
                height: 100%;
                align-items: center;
                padding-left: 40px;
                padding-right: 24px;
                border-bottom: 1px solid $white;
                position: relative;

                &:after {
                    content: "";
                    width: 6px;
                    height: 6px;
                    border-style: solid;
                    border-width: 1px;
                    border-color: $black $white $white $black;
                    transform: rotate(-45deg);
                    position: absolute;
                    top: 20px;
                    right: 9px;
                }
            }
        }

        &__item_account_title {
            background: $black;
            border-bottom: 1px solid $white;
            border-top: 1px solid $black;
            color: $white;
        }

        &__logo {
            background: $black;
            padding: 8px 16px 75px;

            img {
                width: 181px;
                margin: 0 auto;
                display: block;
            }
        }

        &-menu {
            display: block;
            position: relative;
            overflow: hidden;
            transition: height $ease-main;

            &__screen {
                width: 100%;
                position: absolute;
                transform: translate3d(100%, 0, 0);
                transition: transform $ease-main, opacity $ease-main;
                opacity: 0;

                &.is-past {
                    transform: translate3d(-100%, 0, 0);
                    opacity: 0;
                }

                &.is-active {
                    transform: translate3d(0, 0, 0);
                    opacity: 1;
                }
            }

            // Main

            &__main-items {
                padding-top: 12px;
            }

            &__main-item {
                padding: 0 20px;
                height: 47px;
                display: flex;
                align-items: center;
                font-size: 16px;
                line-height: 20px;
                letter-spacing: .06em;
                cursor: pointer;
                transition: opacity $ease-main;

                &:hover {
                    opacity: .5;
                }

                svg {
                    margin-left: auto;
                    width: 7px;
                    height: 12px;
                }
            }

            // Screens

            &__header {
                background: $color-grey-13;
                height: 42px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 16px;
                line-height: 20px;
                letter-spacing: .06em;
                position: relative;
            }

            &__back {
                width: 7px;
                height: 12px;
                position: absolute;
                left: 10px;
                top: 0;
                bottom: 0;
                margin: auto;
                padding: 10px;
                cursor: pointer;
                transition: opacity $ease-main;

                &:hover {
                    opacity: .5;
                }
            }

            &__links {
                margin-top: 14px;
            }

            &__link {
                display: flex;
                align-items: center;
                padding: 8px 20px;
                font-size: 12px;
                line-height: 15px;
                letter-spacing: .06em;
                cursor: pointer;
                transition: opacity $ease-main;

                &:hover {
                    opacity: .5;
                }

                span {
                    flex-grow: 1;
                }

                svg {
                    margin-left: 10px;
                    width: 7px;
                    height: 12px;
                    transform: rotate(180deg);
                }
            }
        }
        &-occassion {
            display: block;
            margin-top: 29px;
            padding: 11px 20px 0;
            background: $color-grey-13;
            border-width: 1px 0;
            border-style: solid;
            border-color: $color-grey-12;

            &__title {
                font-weight: 700;
                font-size: 14px;
                line-height: 17px;
                letter-spacing: .06em;
                color: $color-grey-54;
            }

            &__wrapper {
                overflow-x: auto;
                margin: 0 -20px;
                padding-bottom: 24px;
            }

            &__items {
                margin-top: 10px;
                display: flex;
                align-items: center;
                padding: 0 10px;

                &::after {
                    content: '';
                    width: 10px;
                    height: 1px;
                    flex-shrink: 0;
                }
            }

            &__item {
                flex-shrink: 0;
                text-align: center;
                font-size: 0;
            }

            &__image {
                width: 50px;
                height: 50px;
                display: inline-block;
                border-radius: 50%;
                overflow: hidden;
                position: relative;

                img {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    margin: auto;
                    object-fit: cover;
                    font-family: 'object-fit: cover;';
                    width: 0;
                    min-width: 100%;
                    min-height: 100%;
                }

                .Image {
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    height: auto;
                    padding-bottom: 0;
                    display: block;
                }
            }

            &__text {
                width: 70px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                margin-top: 9px;
                font-weight: 700;
                font-size: 10px;
                line-height: 14px;
                letter-spacing: .06em;
                color: $color-grey-55;
                text-align: center;
            }

            .os-host-resize-disabled.os-host-scrollbar-vertical-hidden > .os-scrollbar-horizontal, .os-host-rtl.os-host-resize-disabled.os-host-scrollbar-vertical-hidden > .os-scrollbar-horizontal {
                padding: 0;
                left: 20px;
                right: 20px;
                height: 5px;
            }

            .os-theme-dark > .os-scrollbar > .os-scrollbar-track > .os-scrollbar-handle {
                background: $color-grey-54;
            }
        }
    }
}




.MenuPage {
    @include mobile {
        background: #fff;
        position: fixed;
        z-index: 101;
        top: 0;
        bottom: 0;
        margin-bottom: 0;
        overflow-y: auto;
        overflow-x: hidden;
        width: 100%;
        margin: 0;

        &::before {
            z-index: -1;
            content: '';
            position: fixed;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
        }

        + .Footer {
            display: none;
        }
    }
}
